import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

// import {
//   uiPath
// } from 'navigation/Constant'

// export const uiPath = path

export let navigate;

export const injectNavigation = _enqueue =>{
    navigate = _enqueue
}

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        //   isMobile = {mobile}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }
// export { uiPath}