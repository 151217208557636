import React, { Component } from 'react';
import { api } from 'services/request';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

export default class Holdings extends Component {
    constructor(props){
        super(props);
        this.state={

        };
    }
    componentDidMount() {
        this.getHolding()
    }
    getHolding=()=>{
        api.get('demat/holdings').then((res)=>{
            this.setState({ holdings:res.data})
        }).catch((err)=>console.log(err))
    }
    render() {
        const columns =[
            {
                field: 'script',
                headerName: 'Script',
                sortable: true,
                width: 160,
                renderCell: (params) => (<Tooltip placement='right' title={params.row.scriptDesc}>
                    <p>{params.row.script}</p>
                </Tooltip>)
                // renderCell: (params: any) =>  (
                //     <Tooltip title={params.data.id} >
                //      <span className="table-cell-trucate">{params.data.id}</span>
                //      </Tooltip>
                //    ),
            },
            {
                field: 'currentBalance',
                headerName: 'Current Balance',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'pledgeBalance',
                headerName: 'Pledge Balance',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'lockingBalance',
                headerName: 'Lockin Balance',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'freezeBalance',
                headerName: 'Freeze Balance',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'freeBalance',
                headerName: 'Free Balance',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'dematPending',
                headerName: 'Demat Pending',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            {
                field: 'remarks',
                headerName: 'Remark',
                // description: 'This column has a value getter and is not sortable.',
                sortable: false,
                minWidth: 200,
                flex:1
                // width: '100%',
                // valueGetter: (params) =>
                //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
        ]
        return (<>
            {this.state.holdings?<div style={{ height: 'inherit', width: '100%' }} sx={{height:'100%'}}>
                <DataGrid
                    // autoHeight
                    // components={{
                    //     Toolbar: GridToolbar
                    //   }}
                    autoPageSize
                    pagination
                    rows={this.state.holdings}
                    getRowId={(r)=>r.isin}
                    columns={columns}
                    disableSelectionOnClick
                    initialState={{
                        sorting: {
                          sortModel: [{ field: 'script', sort: 'asc' }],
                        },
                      }}
                    // pageSize={20}
                    // rowsPerPageOptions={[10,20,40,0]}
                    // checkboxSelection
                />
            </div>:''}
        </>)
    }
}
