import {updateAuth} from 'redux/slice/user';
import {logout} from 'redux/slice/user';
const axios = require('axios');


let store
let enqueueSnackbar

export const injectStore = _store => {
  store = _store
}
export const injectEnqueueSnackbar = _enqueue =>{
    enqueueSnackbar = _enqueue
}

export const apiPath = {
    account:{
        auth:'authenticate/'
    },
    demat:{
        capital:'demat/capital/',
        transaction:'demat/transactions/',
        addAccount:'demat/add-account/',
        updatePassword:'demat/update-password/'
    },
    nots:{
        addAccount:'/nots/add-account/',
        order:'nots/order',
        tmsOrder:'nots/tms-order',
        trade:'nots/trade'
    },
}

const apiInstance = axios.create({
    baseURL:process.env.REACT_APP_API_BASE_URL,
});

apiInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const userState = store.getState().user
    if (userState.auth && userState.auth.aid){ config.headers.Authorization = `TOKEN ${userState.auth.aid}`}
    if (userState.auth && userState.auth.rid){config.headers['x-rid'] = `${userState.auth.rid}`}
    if(userState.account){config.headers['Account']= userState.account}
    if(userState.sAccount){config.headers['X-Account']= userState.sAccount}
    return config;
    }, function (error) {
        
    // Do something with request error
    return Promise.reject(error);
});
apiInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const userState = store.getState().user
    // console.log(response.headers)

    store.dispatch(updateAuth(
        {
            rid:(typeof response.headers['x-rid'] != 'undefined' ? response.headers['x-rid'] : (userState.auth != null ? userState.auth.rid: null)), 
            aid:(typeof response.headers['x-aid'] != 'undefined' ? response.headers['x-aid']:(userState.auth != null ? userState.auth.aid: null))}
        ))
    if (enqueueSnackbar && response.data && response.data.detail){
        enqueueSnackbar(response.data.detail,{variant:'success'});
    }
    return response;
    }, function (error) {
        if (error.response.status === 401){
            console.log('401 error')
            if (enqueueSnackbar){
                enqueueSnackbar('Session Time Out !!!',{variant:'error'});
            }
            if(store){
                store.dispatch(logout());
            }
        } else{
            if (enqueueSnackbar){
                enqueueSnackbar(error.response.data.detail,{variant:'error'});
            }
        }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export const api = apiInstance;
export default api
export const notify = (message, option={})=> {if (enqueueSnackbar){enqueueSnackbar(message,option)}}