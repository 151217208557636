import './App.css';
import "fontsource-roboto";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter} from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";
import {SnackbarProvider, useSnackbar} from 'notistack'
import { createRef } from 'react';
import {Button} from '@mui/material';
import store from 'redux/store';
import {injectStore,} from 'services/request'
import { Provider } from 'react-redux'


const theme = createTheme({
    palette: {
        white:"#FFFFFF"
    }
});

function App() {
    injectStore(store);
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // injectEnqueueSnackbar(enqueueSnackbar);
    const notistackRef = createRef();
    const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    }
  return (
    // <Provider store={store}>
    <div style={{height:'100vh', width:'100%'}}>
        <ThemeProvider theme={theme}>
			<SnackbarProvider preventDuplicate={false} maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            ref={notistackRef}
            action={(key) => (
                <Button onClick={onClickDismiss(key)}>
                    Dismiss
                </Button>
            )}
            >
                <BrowserRouter>
                    <RouterConfig key={'router'}/>
                </BrowserRouter>
			</SnackbarProvider>
        </ThemeProvider>
    </div>
    // </Provider>
  );
}

export default App;
