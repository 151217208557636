import React, { Component } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


import { 
    Box,
    Collapse,
    Drawer,
    Toolbar,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,
    // SwapableDrawer,
    Divider,
} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
    ExpandMore,
    ExpandLess,
} from '@mui/icons-material';
import {
    Outlet, 
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {navObj} from 'navigation/Constant';



class Navigation extends Component  {
    constructor(props){
        super(props);
        this.state={
            mobileMenuShow:false
        }
    }
    toggleMobileMenu(show){
        this.setState({
            mobileMenuShow: show
        })
    }

    render(){
        return <Box sx={{display:'flex', height:'calc(100vh - 0px)'}}>
            { !this.props.isMobile? <Drawer
            variant='permanent'
            sx={{
                width:'240px',
                flexShrink:0,
                display:{
                    xs:'none',
                    sm:'block'
                },
                [`& .MuiDrawer-paper`]: { width: '240px', boxSizing: 'border-box' }
            }}
            >
                <Toolbar/>
                <Box sx={{ overflow: 'auto' , pt:2}}>
                    <List>
                        {navObj.map((nav, index) => (<>
                            <ListItemButton key={`d-${index}`} selected={this.props.router.location.pathname.split('/')[1] === nav.path.split('/')[1]} onClick={()=> this.props.router.location.pathname!== nav.path && this.props.router.navigate(nav.path)}>
                                <ListItemIcon>
                                    {nav.icon}
                                </ListItemIcon>
                                <ListItemText primary={nav.name} />
                                {nav.item.length > 0 ? (this.props.router.location.pathname.split('/')[1] === nav.path.split('/')[1]? <ExpandMore/>: <ExpandLess/>):null}
                            </ListItemButton>
                            <Collapse key={index} in={this.props.router.location.pathname.split('/')[1] === nav.path.split('/')[1]} timeout="auto" unmountOnExit>
                                {nav.item.map((item, index)=>(
                                    item.show?<List component="div" key={index} disablePadding>
                                        <ListItemButton sx={{ pl: 4 }} onClick={()=> this.props.router.location.pathname!== item.path &&  this.props.router.navigate(item.path)}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    </List>:null
                                ))}
                          </Collapse>
                          </>))}
                    </List>
                    <Divider/>
                </Box>
            </Drawer> : null}
            <Box component='main' sx={{flexGrow:this.props.isMobile? 0:1,p:3,width:'calc(100% - 48px)',height:'calc(100% - 6)', mb:this.props.isMobile? '50px':0}}>
                <Toolbar/>
                <Box sx={{height:'calc(100% - 64px)'}}>
                    <Outlet/>
                </Box>
            </Box>
            {this.props.isMobile ? <
            >
                <BottomNavigation
                    showLabels
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display:{sm:'none'} }}
                >   
                {navObj.map((nav,index)=>(
                    <BottomNavigationAction
                    label={nav.name}
                    value={nav.name}
                    icon={nav.icon}
                    key={index}
                    onClick={()=> {if (nav.item.length > 0){this.toggleMobileMenu(nav)} else{this.props.router.navigate(nav.path)}}}
                />
                ))}
            </BottomNavigation> 
                <SwipeableDrawer
                // container={container}
                anchor='bottom'
                open={Boolean(this.state.mobileMenuShow)}
                onOpen={()=>this.toggleMobileMenu(navObj[0])}
                onClose={()=>this.toggleMobileMenu(false)}
                // swipeAreaWidth={ -54}
                // disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx:{
                        maxHeight:'75%',
                        minHeight:'75px',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }
                }}
                >
                   <Box
                   sx={{
                    // position: 'absolute',
                    // minHeight:'350px',
                    // top: -drawerBleeding,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                  }}
                   >
                       { this.state.mobileMenuShow.path? <List>
                            <ListItemButton selected={this.props.router.location.pathname.split('/')[1] === this.state.mobileMenuShow.path.split('/')[1]} onClick={()=> {
                                 if (this.props.router.location.pathname.split('/')[1] !== this.state.mobileMenuShow.path.split('/')[1]){
                                    this.setState({mobileMenuShow:false});
                                    this.props.router.navigate(this.state.mobileMenuShow.path)
                                    }}}>
                                <ListItemIcon>
                                    {this.state.mobileMenuShow.icon}
                                </ListItemIcon>
                                <ListItemText primary={this.state.mobileMenuShow.name} />
                                {/* {this.state.mobileMenuShow.item.length > 0 ? (this.props.router.location.pathname.split('/')[1] === this.state.mobileMenuShow.path.split('/')[1]? <ExpandMore/>: <ExpandLess/>):null} */}
                            </ListItemButton>
                            <Collapse in={this.state.mobileMenuShow.item.length > 0} timeout="auto" unmountOnExit>
                                {this.state.mobileMenuShow.item.map((item, index)=>(
                                    item.show?<List component="div" disablePadding key={index}>
                                        <ListItemButton selected={this.props.router.location.pathname === item.path} sx={{ pl: 4 }} onClick={()=>  {
                                            if (this.props.router.location.pathname !== item.path){
                                                this.setState({mobileMenuShow:false});
                                                this.props.router.location.pathname!== item.path && this.props.router.navigate(item.path);
                                            }}}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    </List>:null
                                ))}
                          </Collapse>
                        </List> : null}
                    </Box>

                </SwipeableDrawer> </>: null}
        </Box>   
    }
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      let theme = useTheme();
      let mobile = useMediaQuery(theme.breakpoints.down('sm'));
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
          isMobile = {mobile}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

export default withRouter(Navigation);