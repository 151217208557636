import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from 'redux/store';
import {injectStore} from 'services/request'
import { Provider } from 'react-redux'

injectStore(store);


ReactDOM.render(
  	<React.StrictMode>
	  	<Provider store={store}>
    		<App />
		</Provider>
  	</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
