import React, { Component } from 'react'
import {navigate, withRouter} from 'navigation'
import {uiPath} from 'navigation/Constant'
import {
    Button,
	IconButton,
	Tab,
	Tabs,
	Box,
} from '@mui/material'
import {
	Cancel,
	Edit
} from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid'
import {api, apiPath, notify} from 'services/request'
import TableBase from 'components/Table'


export class PendingOrderBase extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			order:undefined
		}
	}
	tabValue=0
	tab(){
		return <>
			<Box sx={{ borderBottom: 1, borderColor: 'divider', mb:'15px'}}>
				<Tabs value={this.tabValue} aria-label="basic tabs example">
					<Tab label="Create" onClick={()=> navigate(uiPath.nots.pendingOrderCreate)} />
					<Tab label="Daily" onClick={()=> navigate(uiPath.nots.pendingOrder)} />
					<Tab label="History" onClick={()=> navigate(uiPath.nots.pendingOrderHistory)} />
				</Tabs>
			</Box>
		</>
	}
	renderBody(){
		
	}
	render(){
		return (
			<Box sx={{height:'100%'}}>
				{this.tab()}
				<Box sx={{height:'calc(100% - 55px)'}}>
					{this.renderBody()}
				</Box>
			</Box>
		)
	}
}

class OrderTable extends TableBase {
    cancelOrder = (id) => {
		console.log(id)
	}
    
    url=apiPath.nots.order

    _colDef = () => {
        function changeModifiable (params){
            console.log(params);
            let newRowData = {
                uid:params.row.uid, 
                modifiable:!params.row.modifiable
            }
            console.log(newRowData);
            params.api.updateRows([newRowData])
        }
        return [
            {
                field:'script',
                headerName:'Symbol'
            },
            {
                field:'modified',
                headerName:'Last Modified',
                type:'dateTime',
                minWidth:160,
                valueGetter: ({ value }) => value && new Date(value),
                
            },
            {
                field:'modifiable',
                hide:true,
                editable:true
            },
            {
                field:'action',
                headerName:'Action',
                type:'actions',
                getActions: (params) => [
                	<GridActionsCellItem
                	  icon={<Edit />}
                	  label="Edit"
                	//   onClick={deleteUser(params.id)}
                	 onClick={()=>navigate(`/nots/pending-order/${params.row.uid}`)}
                	 disabled={!params.row.modifiable}
                	/>,
                	<GridActionsCellItem
                	  icon={<Cancel />}
                	  label="Cancel"
                	  onClick={()=>{this.cancelOrder(params.row.uid)}} disabled={!params.row.modifiable}
                	//   showInMenu
                	/>
                  ],
            },
            {
                field:'direction',
                headerName:'Order Type'
            },
            {
                field:'price',
                headerName:'Price'
            },
            {
                field:'qty',
                headerName:'Quantity'
            },
            {
                field:'validity',
                headerName:'Validity Type'
            },
            {
                field:'status',
                headerName:'Status',
                
            },
            {
                field:'remark',
                headerName:'Remark',
                flex:1,
                minWidth:300
            }
        ]
    }
}

export class PendingOrder extends PendingOrderBase {
	tabValue=1
	renderBody(){
		return <OrderTable/>
	}
}

export class CreateOrder extends PendingOrderBase {
  tabValue=0
}

class ModifyOrderInit extends Component {
  render() {
	  console.log(this.props.router.params.id)
    return (
        <>
        <Button onClick={()=>navigate('/nots/pending-order')}>Order</Button>
        <Button onClick={()=>navigate('/nots/pending-order/create')}>Create Order</Button>
        <Button onClick={()=>navigate('/nots/pending-order/history')}>Order History</Button>
    </>
    )
  }
}

export const ModifyOrder = withRouter(ModifyOrderInit)

export class OrderHistory extends PendingOrderBase {
    tabValue=2
	renderBody(){
		return <OrderTable/>
	}
}