import { 
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    useTheme,
    Badge,
    Menu,
    MenuItem,
    useMediaQuery,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import {useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { selectAccount, logout } from "redux/slice/user";

import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';

export default function Header(){
    const theme = useTheme();
    const userState = useSelector(state=> state.user);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    return<>
        <Box sx={{flexGrow:1}}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        // sx={{ display: { } }}
                    >
                        { useMediaQuery(theme.breakpoints.down('sm'))? "FMS" : "Finance Management System"}
                    </Typography>
                    <Box sx={{ flexGrow: 1 , display:{xs:'none', sm:'flex'}}} />
                    <Box sx={{flexGrow:2, margin: '10px', ml:'15px', maxWidth:'350px', minWidth:'200px'}}>
                        <FormControl fullWidth sx={{ borderColor:'white!important', color:'#FFFFFF!important'}}>
                            <InputLabel sx={{ color:'inherit'}}>Account</InputLabel>
                            <Select 
                            inputProps={{color:'white'}}
                            sx={{ color:'inherit'}}
                            value={userState.account ? userState.account: ""}
                            onChange={(e)=>dispatch(selectAccount(e.target.value))}
                            >   
                            {userState.user.account !=null? Object.keys(userState.user.account).map((acc) => <MenuItem value={acc} key={acc}>{userState.user.account[acc].display}</MenuItem> ):""}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ flexGrow: 1 , display:{xs:'none', sm:'flex'}}} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                        // sx={{mt:'40px'}}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        // id={menuId}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                        >
                        <MenuItem onClick={handleMenuClose}>Info</MenuItem>
                        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
                        {userState.account? userState.user.account[userState.account].is_self?<MenuItem onClick={handleMenuClose}>Access Control</MenuItem>:null:null}
                        <MenuItem onClick={()=>{handleMenuClose(); dispatch(logout())}}>Logout</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="show more"
                        // aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                        <Menu
                        anchorEl={mobileMoreAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        // id={mobileMenuId}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={isMobileMenuOpen}
                        onClose={handleMobileMenuClose}
                        >
                            <MenuItem>
                                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                                <Badge badgeContent={4} color="error">
                                    <MailIcon />
                                </Badge>
                                </IconButton>
                                <p>Messages</p>
                            </MenuItem>
                            <MenuItem>
                                <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                >
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon />
                                </Badge>
                                </IconButton>
                                <p>Notifications</p>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* {this.props.children} */}
        </Box>
        
    </>
}