import {
    Dashboard as DashboardIcon,
    CurrencyExchange as NotsIcon,
    RequestPage as DematIcon,
} from '@mui/icons-material';
import {
    DematOption
} from 'pages/private/SectionHeader';
import DematDetail from 'pages/private/demat/Detail';
import DematHolding from 'pages/private/demat/Holdings';
import DematTransaction from 'pages/private/demat/Transactions';
import {
    PendingOrder as NotsPendingOrder,
    CreateOrder as NotsCreateOrder,
    ModifyOrder as NotsModifyOrder,
    OrderHistory as NotsHistoryOrder
} from 'pages/private/nots/Orders'
import {
    TmsOrderPending
} from 'pages/private/nots/TmsOrder'

export const uiPath = {
    nots:{
        pendingOrder:'/nots/pending-order',
        pendingOrderCreate:'/nots/pending-order/create',
        pendingOrderModify:'/nots/pending-order/:id',
        pendingOrderHistory:'/nots/pending-order/history'
    }
}

export const navObj=[
    {
        name:'Dashboard',
        icon:<DashboardIcon/>,
        path:'/',
        optionMenu:null,
        item:[]
    },
    {
        name:'Demat',
        icon:<DematIcon/>,
        path:'/demat',
        optionMenu:<DematOption/>,
        item:[
            {
                name:'Dashboard',
                path:'/demat',
                show:true,
                element:null,
                icon:""
            },
            {
                name:'Detail',
                path:'/demat/detail',
                show:true,
                element:<DematDetail/>,
                icon:''
            },
            {
                name:'Holdings',
                path:'/demat/holdings',
                show:true,
                element:<DematHolding/>,
                icon:''
            },
            {
                name:'Profile',
                path:'/demat/profile',
                show:false,
                element:null,
                icon:''
            },
            {
                name:'Transactions',
                path:'/demat/transactions',
                show:true,
                element:<DematTransaction/>,
                icon:''
            },
        ]
    },
    {
        name:'NOTS',
        icon:<NotsIcon/>,
        path:'/nots',
        optionMenu:null,
        item:[
            {
                name:'Dashboard',
                path:'/nots',
                show:true,
                element:null,
                icon:""
            },
            {
                name:'Pending Orders',
                path:uiPath.nots.pendingOrder,
                show:true,
                element:<NotsPendingOrder/>,
                icon:""
            },
            {
                name:'Create Pending Order',
                path:uiPath.nots.pendingOrderCreate,
                show:false,
                element:<NotsCreateOrder/>,
                icon:""
            },
            {
                name:'Pending Order History',
                path:uiPath.nots.pendingOrderHistory,
                show:false,
                element:<NotsHistoryOrder/>,
                icon:""
            },
            {
                name:'Modify Pending Orders',
                path:uiPath.nots.pendingOrderModify,
                show:false,
                element:<NotsModifyOrder/>,
                icon:""
            },
        ]
    }
]