import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name:'user',
    initialState:{
        user:null,
        account:null,
        sAccount:null,
        auth:null,
    },
    reducers:{
        login: (state, data) => {
            state.user=data.payload
            // Object.keys(data.payload.account).map(key =>{
            //     state.account= !data.payload.account[key].is_self? data.payload.account[key]: null
            // })
        },
        logout: state =>{
            state.user=null
            state.auth=null
            state.account=null
        },
        selectAccount: (state, account)=>{
            state.account=account.payload
        },
        updateAuth:(state, data)=>{
            state.auth=data.payload
        },
        selectSAccount:(state, data)=>{
            state.sAccount=data.payload
        },
        unselectSAccount:(state,data)=>{
            state.sAccount=null
        }
    }
});
export const {login, logout, selectAccount, updateAuth, selectSAccount, unselectSAccount} = userSlice.actions

export default userSlice.reducer