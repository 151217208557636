
import React, { Component } from 'react';
import {
    IconButton,
	Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Select,
    MenuItem,
    FormControl,
    Button,
    Skeleton,
} from '@mui/material';
import {
	Mail as MailIcon,
	Notifications as NotificationsIcon,
} from '@mui/icons-material'
import {
    connect
} from 'react-redux'
import {
    UsernameField,
    PasswordField
} from 'pages/Form'
import {api,apiPath, notify} from 'services/request';

class DematOpt extends Component {
    constructor(props){
        super(props);
        this.state={};
        // this.closeAddAccountDialogue = this.closeAddAccountDialog.bind(this);
        // this.addAccount = this.addAccount.bind(this);
        

    }
    _getCapital = () =>{
        api.get(apiPath.demat.capital).then((res)=>{
            this.setState(
                {capital:res.data},
                console.log(this.state.capital)
            )
        })
    }
    submitAddAccount = (e)=>{
        e.preventDefault();
        api.post(apiPath.demat.addAccount,{
            dp:this.state.dematCap,
            username:this.state.dematAccUser,
            password:this.state.dematAccPw
        },{timeOut:60}).then((res)=>{
            this.closeAddAccountDialogue();
            // notify('Demat Account Added Successfully. It may take some time to Setup.')
        })
    }
    submitUpdatePw=(e)=>{
        e.preventDefault();
        api.post(apiPath.demat.updatePassword,{
            code:this.state.dematAccPw
        }).then(()=>this.setState({updatePwDialogue:undefined,dematAccPw:undefined}))
    }
    closeAddAccountDialogue=()=>{
        this.setState({AddAccountDialogue:undefined,capital:undefined,dematAccUser:undefined,dematAccPw:undefined,dematCap:undefined});
    }
    render() {
        return (<>
        {this.props.userState.user.account[this.props.userState.account].is_self ? <>
            <MenuItem onClick={() => this.setState({AddAccountDialogue:true},this._getCapital())}>
                <IconButton size="large"color="inherit">
                {/* <Badge badgeContent={4} color="error">
                    <MailIcon />
                </Badge> */}
                </IconButton>
                <p>Add Account</p>
            </MenuItem>
            {this.state.AddAccountDialogue? <Dialog 
            open={this.state.AddAccountDialogue}
            onClose={this.closeAddAccountDialogue}
            >
                <DialogTitle>
                    Add Demat Account
                </DialogTitle>
                <DialogContent>
                    Pleae Provide Meroshare credentials.
                    <Divider/>
                    <FormControl component="form" onSubmit={this.submitAddAccount} sx={{
                            '& > :not(style)': { m: 1 },
                        }}>
                            {Boolean(this.state.capital)?<>
                                <Select onChange={(e)=> this.setState({dematCap:e.target.value})} value={this.state.dematCap? this.state.dematCap : ''} >
                                    {this.state.capital.map((capital,index)=>(
                                        <MenuItem key={index} value={capital.id}>{capital.code} - {capital.name}</MenuItem>
                                    ))}
                                </Select>
                                {/* {this.state.capital.map((capital,index)=>{
                                        capital.name
                                    })} */}
                                <UsernameField onChange={(e)=>this.setState({dematAccUser:e.target.value})} value={this.state.dematAccUser}/>
                                <PasswordField onChange={(e)=>this.setState({dematAccPw:e.target.value})} value={this.state.dematAccPw}/>
                                <DialogActions>
                                    <Button onClick={this.closeAddAccountDialogue}>Cancel</Button>
                                    <Button type='submit'>Submit</Button>
                                </DialogActions>
                            </>:<>
                            <Skeleton animation="wave" variant="rectangular" width={250} height={30} />
                            <Skeleton animation="wave" variant="rectangular" height={30} />
                            <Skeleton animation="wave" variant="rectangular" height={30} />
                            </>}
                        
                    </FormControl>
                    
                </DialogContent>

            </Dialog>:null}
            <MenuItem disabled={this.props.userState.sAccount? false:true} onClick={()=>this.setState({updatePwDialogue:true})}>
                <IconButton size="large"color="inherit">
                {/* <Badge badgeContent={4} color="error">
                    <MailIcon />
                </Badge> */}
                </IconButton>
                <p>Update Password</p>
            </MenuItem>
            {this.props.userState.sAccount && this.state.updatePwDialogue? <Dialog 
            open={this.state.updatePwDialogue}
            onClose={()=>this.setState({updatePwDialogue:undefined,dematAccPw:undefined})}
            >
                <DialogTitle>
                    Update Demat Password
                </DialogTitle>
                <DialogContent>
                    Please Provide Meroshare credentials.
                    <Divider/>
                    <FormControl component="form" onSubmit={this.submitUpdatePw} sx={{
                        '& > :not(style)': { m: 1 },
                    }}>
                        <UsernameField disabled username={this.props.userState.user.account[this.props.userState.account].access.demat[this.props.userState.sAccount].displayName}/>
                        <PasswordField onChange={(e)=>this.setState({dematAccPw:e.target.value})} password={this.state.dematAccPw}/>
                        <DialogActions>
                            <Button onClick={()=>this.setState({updatePwDialogue:undefined})}>Cancel</Button>
                            <Button type='submit'>Submit</Button>
                        </DialogActions>
                    </FormControl>
                    
                </DialogContent>

            </Dialog>:null}
        </>:null}
        <MenuItem>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
                <MailIcon />
            </Badge>
            </IconButton>
            <p>Messages</p>
        </MenuItem>
        <MenuItem>
            <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            >
            <Badge badgeContent={17} color="error">
                <NotificationsIcon />
            </Badge>
            </IconButton>
            <p>Notifications</p>
        </MenuItem>
        </>);
    }
}
export const DematOption = connect(
    (state)=>({
        userState:state.user
    }),
)(DematOpt)
