import React, { Component } from 'react'
import {api} from 'services/request'
import {
    Paper,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material'

export default class Detail extends Component {
    constructor(props){
        super(props);
        this.state={
            detail:null
        }
    }
    componentDidMount(){
        this.getDetail();
    }
    getDetail = () =>{
        api.get('demat/details/').then(res => this.setState({detail:res.data})).catch(e=>console.log(e))
    }
    render() {
        const detailKey={
            // "uid":"60a4e529-23f8-44d3-9fbe-056eda169c57",
            "dpName":"DP Name",
            "boid":'BOID',
            "name":"Name",
            "gender":"Gender",
            "fatherMotherName":"Father / Mother Name",
            "grandfatherSpouseName":"Grandfather / Spouse Name",
            "subStatus":"Status",
            // "accountStatusFlag":"ACTIVE",
            // "bankCode":1801,
            "bankName":"Bank Name",
            "accountNumber":'Account Number',
            "accountOpenDate":"Account Open Date",
            // "accountStatus":'Account Status',
            "accountStatusName":"Account Status",
            "accountType":"Account Type",
            "address":"Address","aod":"Account Opening Date",
            // "branchCode":30,
            // "capital":10900,
            // "confirmationWaived":"Yes",
            "contact":"Contact No.",
            "dateOfBirth":"Date of Birth",
            // "dob":"2001-01-16T00:00:00+05:45",
            // "citizenCode":"Citizen Code",
            "citizenshipNumber":"Citizenship Number",
            // "regexCitizenNumber":6017400368,
            "issuedDate":"Issued Year",
            "issuedFrom":"Issued from",
            // "subStatusCode":3,
            // "suspensionFlag":false
        }
        return (<>{this.state.detail? <TableContainer component={Paper} sx={{height:'100%'}}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableBody>
                {Object.keys(detailKey).map((row) => (
                  <TableRow
                    key={row}
                  >
                    <TableCell component="th" scope="row">
                      {detailKey[row]}
                    </TableCell>
                    <TableCell align="right">{this.state.detail[row]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>:null}</>)
    }
}
