import React, { Component } from 'react'
import {api, apiPath} from 'services/request'

import { DataGrid } from '@mui/x-data-grid';

export default class Transactions extends Component {
    constructor(props){
        super(props);
        this.state={
            transaction:null
        }
    }
    componentDidMount() {
        this.getTransactions()
    }

    getTransactions = () =>{
        api.get(apiPath.demat.transaction).then((res) => {this.setState({transaction:res.data})}).catch((err) => {})
    }
    render() {
        const columns = [
            {
                field:'transactionDate',
                headerName:'Date',
                type:'dateTime',
                description:'Trasaction Date'
            },
            {
                field:'script',
                headerName:'Script'
            },
            {
                field:'debitQty',
                headerName:"Debit"
            },
            {
                field:'creditQty',
                headerName:'Credit'
            },
            {
                field:'balAfterTrans',
                headerName:'Balance'
            },
            {
                field:'historyDesc',
                headerName:'Description',
                minWidth:250,
                flex:1
            }
        ]
        return (<>{this.state.transaction? <DataGrid 
                autoPageSize
                pagination
                rows={this.state.transaction}
                getRowId={(r)=>String(`${r.transactionDate}-${r.script}-${r.balAfterTrans}`)}
                columns={columns}
                disableSelectionOnClick
            />:null}
        </>)
    }
}
