import React, { Component } from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import Navigation from 'pages/private/NavMenu';
import {connect, useSelector} from 'react-redux';
import { withSnackbar } from 'notistack';
import {
	selectSAccount,
	unselectSAccount
} from 'redux/slice/user';

import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Toolbar,
	Typography,
	Menu,
	Tooltip,
} from '@mui/material';
 import {
	MoreVert as MoreIcon,
 } from '@mui/icons-material'

import Header from 'pages/private/AppBar';
import SelectAccountWarning from 'components/SelectAccountWarning';
import { navObj } from 'navigation/Constant';
import { withRouter } from 'navigation';


class DB extends Component {
	constructor(props){
		super(props);
		this.state = {
			optionMenuAnchor:null
		}
	}
	componentWillUnmount(){
		this.props.dispatch(unselectSAccount())
	}
	componentDidUpdate(prevProps) {
		if (prevProps.section !== this.props.section) {
			this.props.dispatch(unselectSAccount());
		};
		if (prevProps.userState.sAccount !== this.props.userState.sAccount){
			return true;
		}
	}
  	render() {
		const gettitle = () => {
			for( const section of navObj){
				if(section.path !== '/' && this.props.router.location.pathname.split('/')[1] === section.path.split('/')[1]){
						for(const sectionPath of section.item){
							if (sectionPath.path === this.props.router.location.pathname){
								if(this.props.section === 'nots'){
									return `${this.props.section.toUpperCase()} ${sectionPath.name}`
								}else{
									return `${this.props.section.charAt(0).toUpperCase() + this.props.section.slice(1)} ${sectionPath.name}`
								}
							}
						}
				}
			};
			return 'Dashboard'
		}
		const title = gettitle()
		navObj.map((section)=>{
			if(this.props.router.location.pathname.split()[1] === section.path.split()[1]){
				return section.item.map((sectionPath)=>{
					if (sectionPath.path === this.props.router.location.pathname){
						if(this.props.section === 'nots'){
							return `${this.props.section.toUpperCase()} ${sectionPath.name}`
						}else{
							return `${this.props.section.charAt(0).toUpperCase() + this.props.section.slice(1)} ${sectionPath.name}`
						}
					}else{
						return this.props.section.toUpperCase()
					}
				})
			}else{return 'Dashboard'}
		})
		
		return (<>
		{ this.props.userState.account? <Box sx={{height:'100%'}}>
			< Toolbar sx={{flexGrow:1, p:'0!important', m:'15px'}}>
				<Tooltip title={title} placement="bottom-start">
					<Typography variant='h4' noWrap={true} sx={{m:0, pr:'10px'}}>
						{title}
					</Typography>
				</Tooltip>
				<Box sx={{ flexGrow: 1 , display:{xs:'none', sm:'flex'}}} />
				{this.props.userState.user.account[this.props.userState.account] && this.props.userState.user.account[this.props.userState.account].access && this.props.userState.user.account[this.props.userState.account].access[this.props.section] ? <FormControl sx={{flexGrow:1,minWidth:"160px", maxWidth:'350px' }}>
					<InputLabel> {this.props.section.charAt(0).toUpperCase() + this.props.section.slice(1)} Account</InputLabel>
					<Select
					value={this.props.userState.sAccount && Object.keys(this.props.userState.user.account[this.props.userState.account].access[this.props.section]).includes(this.props.userState.sAccount)? this.props.userState.sAccount : ''}
					// defaultValue={this.props.userState.sAccount && Object.keys(this.props.userState.user.account[this.props.userState.account].access[this.props.section]).includes(this.props.userState.sAccount)? this.props.userState.sAccount : null}
					onChange={(e)=>this.props.dispatch(selectSAccount(e.target.value))}
					>
						{Object.keys(this.props.userState.user.account[this.props.userState.account].access[this.props.section]).map(
							(acc) => <MenuItem value={acc}>{this.props.userState.user.account[this.props.userState.account].access[this.props.section][acc].displayName}</MenuItem>
						)}
					</Select>
				</FormControl>: this.props.enqueueSnackbar("Looks Like you don't have access to ths section",{preventDuplicate:true}) && <Navigate to='/'/>}
				{Boolean(this.props.optionMenu)?<Box sx={{pl:'10px'}}>
					<IconButton
					size="large"
					aria-label="show more"
					aria-haspopup="true"
					onClick={(e)=>this.setState({optionMenuAnchor:e.currentTarget})}
					color="inherit"
					>
						<MoreIcon />
					</IconButton>
					<Menu
					anchorEl={this.state.optionMenuAnchor}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(this.state.optionMenuAnchor)}
					onClose={()=>this.setState({optionMenuAnchor:null})}
					>
						{this.props.optionMenu}
					</Menu>
				</Box>:""}
			</Toolbar>
			{this.props.userState.sAccount && Object.keys(this.props.userState.user.account[this.props.userState.account].access[this.props.section]).includes(this.props.userState.sAccount)?<>
			<Box sx={{height:'calc(100% - 94px)'}}>
				<Outlet key={this.props.userState.sAccount}/>
			</Box>
			

			</>:<SelectAccountWarning/>}
			</Box>:<SelectAccountWarning/>
		}</>);
	}
}
export const SectionHeader = withSnackbar(withRouter(connect(
	(state)=>({
		userState:state.user
	}),
)(DB)))


export function AuthRequired() {
	const userState = useSelector(state=> state.user);

	if (!userState.user){
		return <Navigate to="/login" />
	}
	return  <>
	<Header/>
	<Navigation key={userState.account} sx={{height:'100vh'}}>
		{/* <Outlet/> */}
	</Navigation>
	</>
}



