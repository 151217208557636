import React, { Component } from 'react'
import {navigate, withRouter} from 'navigation'
import {
    Button,
	IconButton,
} from '@mui/material'
import {
	Cancel,
	Edit
} from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import {api, apiPath, notify} from 'services/request'

class TableBase extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            tableData: undefined
        }
    }

    componentDidMount() {
        this._getData();
    }

    _getData = () => {
		this.setState({tableData:'loading'})
		api.get(this.url).then(res=>{
			this.setState({tableData:res.data})
		}).catch(e => {this.setState({tableData:'error'});notify('Something went Wrong.')})
	}
    _getRowId = (r) => {
        return r.uid
    }
    _colDef = () => {return []}

    render() {
        return (
            <>{this.state.tableData ? 
                this.state.tableData === 'loading'? 
                    'loading' 
                    :this.state.tableData === 'error'?
                        'error'
                        :<DataGrid
                        columns={this._colDef()} 
                        rows={this.state.tableData}
                        getRowId={this._getRowId}
                        initialState={this.props.initialState}
                        disableSelectionOnClick
                        {...this.props.dt}
                        ></DataGrid>
            :'no Data'}</>
        )
    }
}
export default TableBase;