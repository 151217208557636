import React, { Component } from 'react'
import {
    Box,
} from '@mui/material';
import { WarningAmber } from '@mui/icons-material';

export default class SelectAccountWarning extends Component {
  render() {
    return (
        <Box sx={{
			height:'100%',
			minHeight:'80vh',
			width:'100%',
			display:'flex',
			// direction:'column',
			justifyContent:'center',
			alignItems:'center'
		}}>
			<WarningAmber color='warning' fontSize='large'/>
			<h2> Please select Account first.</h2>
		</Box>
    )
  }
}
