import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Box,
    Grid,
    Card,
    CardContent,
    Button,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import {Visibility, VisibilityOff, Person} from "@mui/icons-material";
import FinanceImg from 'assets/img/finance.jpg';
import { withSnackbar } from 'notistack';
import {Navigate} from 'react-router-dom';
import {login} from 'redux/slice/user';
import {api, apiPath} from 'services/request'

class Login extends Component {
    constructor(props){
        super(props);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.state = {
            showPassword:false,
            username:'',
            password:'',
			usernameError:null,
			passwordError:null,
        }
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleUsername=this.handleUsername.bind(this);
        this.handlePassword=this.handlePassword.bind(this);
        this.submitCred=this.submitCred.bind(this);
    }
    handleClickShowPassword() { this.setState({showPassword:!this.state.showPassword, usernameError:null});}
    handleMouseDownPassword() {this.setState({showPassword:!this.state.showPassword, passwordError:null});}
    handleUsername(e){ this.setState({username:e.target.value})}
    handlePassword(e){ this.setState({password:e.target.value})}
    submitCred (){
		if (this.state.username && this.state.password){
            api.post(apiPath.account.auth,{
                username:this.state.username,
                password:this.state.password
            }).then(response => {
                this.props.login(response.data); 
                this.setState({
                    username:'',
                    password:''
                });
                this.props.enqueueSnackbar('Logged in Successfully', {
                    variant:'success', 
                    autoHideDuration:3000
            })}).catch(error => {
                this.props.enqueueSnackbar('Login failed',{variant:'error'})
                console.log(error)
            })
		} else if (!this.state.username){
			this.setState({usernameError:'Please enter Username or Email'})
		} else if (!this.state.password){
			this.setState({passwordError:'Please Enter Password'})
		}else {
			this.props.enqueueSnackbar('Please Enter Username or Password Properly',{variant:'warning'})
		}
    }
  render() {
	if (this.props.userState.user){
		return <Navigate to='/' />
	}
    return <Grid container alignItems='center' justify='center' sx={{ backgroundImage:`url(${FinanceImg})`, backgroundSize:'cover', backgroundPosition:'center', height:'100vh', justifyContent:'stretch'}}>
        <Grid item  sm={8} sx={{ display:{xs:'none', sm:'flex'}, height:'100vh'}}></Grid>
        <Grid item sm={4}>
            <Grid container spacing={0} alignItems='center' sx={{justifyContent:'space-around'}}>
                <Card sx={{width:'80%', maxWidth:'450px'}}>
                    <CardContent>
                        <Typography variant='h5' align="center">
                            Finance Management System
                            <br/><br/>Login
                        </Typography>
                        <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="on"
						onSubmit={(e)=> {e.preventDefault(); this.submitCred()}}
                        >
                            <TextField label="Username or Email" value={this.state.username} sx={{width:'-webkit-fill-available'}} variant="standard" InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="user"
                                    >
                                      <Person/>
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              onChange={this.handleUsername}
							  errortext={this.state.usernameError}
                              />
                            <TextField  sx={{width:'-webkit-fill-available'}} value={this.state.password} type={this.state.showPassword? 'test':'password'}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDownPassword}
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            label="Password" onChange={this.handlePassword} variant="standard" errortext={this.state.passwordError} />
                            <Button type='submit'>Submit</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Grid>;
  }
}

export default withSnackbar(
    connect((state)=>({userState:state.user}), (dispatcher)=>({
    login:(detail)=>dispatcher(login(detail))
}))(Login)
);