import React from "react";
import { Routes, Route, Navigate, useNavigate} from "react-router-dom";
import Login from 'pages/public/Login'
import {SectionHeader, AuthRequired} from 'pages/private/index'
import { navObj } from 'navigation/Constant';
import { useSnackbar} from 'notistack';
import { injectEnqueueSnackbar} from 'services/request'
import { injectNavigation } from "navigation";

export const RouterConfig = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    injectNavigation(navigate);
    injectEnqueueSnackbar(enqueueSnackbar);
    return (
        <Routes>
            <Route path="/" element={
                <AuthRequired/>
            }>
                {navObj.map(section=>(
                    section.name.toLowerCase() === 'dashboard'?null:<Route path={`/${section.name.toLowerCase()}`} key={section.name.toLowerCase()} element={<SectionHeader section={section.name.toLowerCase()} optionMenu={section.optionMenu} title='Demat Dashboard'/>}>
                        {section.item.map(path=>(
                            <Route path={path.path} key={path} element={path.element}/>
                        ))}
                    </Route>
                ))}
                <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
            <Route path='login' element={<Login/>}/>
            <Route path='*' element={<Navigate to='/'/>}/>
        </Routes>
    );
};
